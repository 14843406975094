export default {
  h1: {
    fontWeight: 600,
    fontSize: "62px",
    fontFamily:"prompt",
    letterSpacing:"6px",
    color:"#fff",
    "@media (max-width:767px)": {
      fontSize: "45px",
      lineHeight: "50px",
      letterSpacing:"4px",
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: "42px",
    fontFamily:"prompt",
    letterSpacing:"5px",
    color:"#fff",
    "@media (max-width:767px)": {
      fontSize: "32px",
      lineHeight: "44px",
      letterSpacing:"2px",
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: "32px",
    fontFamily:"prompt",
    letterSpacing:"6px",
    color:"#fff",
    "@media (max-width:767px)": {
      fontSize: "22px",
      lineHeight: "44px",
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: "24px",
    fontFamily:"prompt",
    letterSpacing:"3px",
    color:"#fff",
    "@media (max-width:767px)": {
      fontSize: "18px",
      lineHeight: "29px",
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: 18,
    fontFamily:"prompt",
    letterSpacing:"4px",
    color:"#fff",
    "@media (max-width:767px)": {
      fontSize: "16px",
      lineHeight: "44px",
    },
  },
  h6: {
    fontFamily:"Roboto",
    fontWeight: 400,
    fontSize: 16,
    color:"#717475",
    "@media (max-width:767px)": {
      fontSize: "14px",
      lineHeight: "25px",
    },
  },
  body1:{
    fontFamily:"Roboto",
    fontWeight: 400,
    fontSize: 12,
    color:"#717475"
  },
  body2:{
    fontFamily:"Roboto",
    fontWeight: 400,
    fontSize: 14,
    color:"#717475",
    "@media (max-width:767px)": {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },

};
