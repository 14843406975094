import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";

import Scroll from "react-scroll";
import { TiSocialFacebook, TiSocialTwitter } from "react-icons/ti";
import { AiFillApple, AiOutlineBehance } from "react-icons/ai";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  labeltext: {
    padding: "0em 0em 0.7em",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#fff",
    display: "block",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: "0.25em",
  },
  dialoginputbox: {
    width: "393px",
    height: "35px",
    border: "2px solid #fff",
    paddingLeft: "10px",
    color: "#fff",
    backgroundColor: "#141414",
    borderRadius: "8px",
    "@media (max-width: 900px)": {
      width: "291px",
    },
  },
  menuButton: {
    fontSize: "16px",

    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",

    color: "#919393",
    fontFamily: "prompt",

    padding: "0px 8px",
    letterSpacing: "1px",
    margin: "0 5px",
    "&.active": {
      color: "#1EB808",
    },
    "&[tabIndex]:focus": {
      color: "#1EB808",
    },

    "&:hover": {
      color: "#1EB808",
    },
    "@media (max-width: 1400px)": {
      fontSize: "15px",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      fontSize: "16px",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#919393",
      padding: "7px 15px !important",
      height: "33px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      fontFamily: "prompt",
    },
  },

  toolbar: {
    display: "flex",
    padding: "20px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#070c0e",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& .btnBox": {
      position: "absolute",
      bottom: "20px",
      left: "20px",
    },
    "& svg": {
      fontSize: "25px",
      background: "#bafd01",
      color: "#000",
      borderRadius: "20%",
      cursor: "pointer",
      marginRight: "15px",
      padding: "10px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "6px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      // width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
    paddingRight: "25px",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "7px 0px 0px",
    minHeight: "60px !important",
  },
  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    position: "relative !important",
    zIndex: "999 !important",
    paddingLeft: "25px",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },

  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    height: "50px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#919393",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },

  topBox: {},
}));

export default function Header({ buttonClick }) {
  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Container className="p-0">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {/* <Grid item xs={2}>
              {productLogo}
            </Grid> */}
            {/* <Grid item xs={7} align="center">{getMenuButtons()}</Grid> */}
            <Grid item xs={12} align="center">
              {" "}
              {menuText}{" "}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box ml={1}>{productLogo}</Box>
            {menuText}
            <div style={{ padding: "16px" }}>{connectBtn}</div>
            <Box className="btnBox">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialFacebook />
              </a>

              <a
                href="https://www.behance.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineBehance />
              </a>

              <a
                href="https://support.apple.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillApple />
              </a>

              <a
                href="https://twitter.com/i/flow/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialTwitter />
              </a>
            </Box>
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#919393", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const connectBtn = <Box></Box>;

  const menuText = (
    <nav>
      <ul class={menuul}>
        <Box className={menuLeft}>
          <div className="logoDesk">
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="home"
            >
              {" "}
              {productLogo}
            </ScrollLink>
          </div>
        </Box>
        <li>
          <li className="activecolor">
            <ul className={inerrMenu}>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="feature"
                  tabIndex="1"
                  onClick={() => history.push("/?id=feature")}
                >
                  Features
                </ScrollLink>{" "}
              </li>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="asset"
                  tabIndex="2"
                  onClick={() => history.push("/?id=asset")}
                >
                  Asset
                </ScrollLink>{" "}
              </li>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="review"
                  tabIndex="3"
                  onClick={() => history.push("/?id=review")}
                >
                  Team
                </ScrollLink>{" "}
              </li>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="faq"
                  tabIndex="4"
                  onClick={() => history.push("/?id=faq")}
                >
                  Faq
                </ScrollLink>{" "}
              </li>
              <li>
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="team"
                  tabIndex="5"
                  onClick={() => history.push("/?id=team")}
                >
                  Whitepaper
                </ScrollLink>
              </li>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  tabIndex="6"
                  onClick={() => history.push("/stake")}
                >
                  Docs
                </ScrollLink>{" "}
              </li>
            </ul>
          </li>
        </li>
      </ul>
    </nav>
  );
  const [scrollValue, setScrollValue] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setScrollValue(scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <AppBar
        // position={history.location.pathname !== "/" ? "relative" : "absolute"}
        className="headerNav"
        elevation={0}
        style={
          scrollValue >= 129
            ? { background: "#070c0d" }
            : { background: "transparent" }
        }
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
