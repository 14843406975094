import React from "react";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";

import {} from "react-feather";
import { Link } from "react-router-dom/cjs/react-router-dom";
const useStyles = makeStyles((theme) => ({
  // footerSection: {
  //   // background: "#f30065",
  //   position: "relative",
  //   padding: "50px 0px",
  //   backgroundPosition: " bottom left",
  //   backgroundRepeat: "no-repeat",
  //   backgroundSize: "cover",
  //   [theme.breakpoints.up("sm")]: {
  //     paddingTop: theme.spacing(4),
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     paddingTop: theme.spacing(4),
  //   },
  //   // "&"
  //   "& h5": {
  //     fontWeight: "bold",
  //     fontSize: "16px",
  //     letterSpacing: "2px",
  //     textTransform: "uppercase",
  //     color: "#2f086a",
  //   },
  //   "& ul": {
  //     paddingLeft: "0",
  //   },
  //   "& p": {
  //     marginBottom: "0px",
  //     marginTop: "10px",
  //     fontWeight: "500",
  //     fontSize: "12px",
  //     lineHeight: "18px",
  //     color: "#000000",
  //   },
  // },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#000",
      minWidth: "90px",
      fontSize: "18px",
      fontWeight: "700",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
  borderBottomSection: {
    backgroundColor: "#070c0e",
    padding: "10px 0px",
    borderTop: "2px solid #383839",
    display: "flex",
    justifyContent: "space-between",
    "& a":{
      fontFamily:"Roboto",
      fontWeight: 400,
      fontSize: 14,
      color:"#717475",
      textDecoration:"none",
      "@media (max-width:767px)": {
        fontSize: "12px",
        lineHeight: "20px",
      },
    }
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box
        className={classes.footerSection}
        style={{ backgroundColor: "#070c0e" }}
        // style={{ backgroundImage: "url('./images/footerIMG.png')" }}
      >
        <Container>
          <Box className={classes.borderBottomSection}>
            <Box>
              <Typography variant="body2">© 2023 Deo Foundation</Typography>
            </Box>
            <Box display="flex">
              <Box>
                <Link to="/privacy-policy">Privacy</Link>
              </Box>
              <Box ml={2}>
                <Link to="/terms-and-condition">Terms</Link>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
